import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================
//==============SAGA======================

export const sagaActions = {
    fetchReportChannelList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_CHANNEL,
        object
    }),

    fetchReportFundTransferList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_FUND_TRANSFER_LIST,
        object
    }),

    fetchReportFundOutList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_FUND_OUT_LIST,
        object
    }),

    fetchReportTotalList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_TOTAL_LIST,
        object
    }),

}


//===============REDUX===================

export const reduxActions = {
    setReportFundTransferList: (list): any => ({
        type: ReduxActionTypes.SET_REPORT_FUND_TRANSFER_LIST,
        list
    }),

    setReportChannelList: (list): any => ({
        type: ReduxActionTypes.SET_REPORT_CHANNEL_LIST,
        list
    }),

    setReportFundOutList: (list): any => ({
        type: ReduxActionTypes.SET_REPORT_FUND_OUT_LIST,
        list
    }),

    setReportTotalList: (list): any => ({
        type: ReduxActionTypes.SET_REPORT_TOTAL_LIST,
        list
    }),
}

