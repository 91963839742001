export const API_URLS = {
    LOGIN: 'api/account/login',
    SIGNUP: 'api/account/register',
    GET_PROFILE: 'api/account/profile',
    CHANGE_PASSWORD: 'api/account/change_password',
    UPDATE_PROFILE: 'api/account/update',
    CHANGE_PASSWORD_SETTLEMENT: 'api/account/change_password_settlement',
    CHECK_STATUS_SETTLEMENT_PASSWORD: 'api/account/check_status_settlement_password',
    GET_QRCODE_2FA: 'api/account/get_qrcode_2fa',
    VERIFICATION_QRCODE_2FA:'api/account/verification_qrcode_2fa',
    VERIFICATION_LOGIN: 'api/account/verification_login',

    GET_REGION_LIST: 'api/region/get',
    GET_STATE_LIST: 'api/state/get',

    // Deposit
    GET_DEPOSIT_LIST: 'api/member_deposit/get',
    CREATE_MEMBER_DEPOSIT: 'api/member_deposit/create',
    GET_AVAILABLE_BANK: 'api/member_deposit/get_available_bank',

    //Settlement
    GET_SETTLEMENT_HISTORY_LIST: 'api/settlement/get',
    CREATE_SETTLEMENT: 'api/settlement/create',

    //FundOut
    GET_FUND_OUT_HISTORY_LIST: 'api/fund_out/get',
    CREATE_FUND_OUT: 'api/fund_out/create',
    REJECT_FUND_OUT: 'api/fund_out/reject',

    //Notification
    SEARCH_NOTIFICATION: 'api/account/inbox',
    UPDATE_NOTIFICATION: 'api/account/update_inbox',

    //Bank
    GET_ACTIVE_BANK_ACCOUNT_LIST: 'api/bank_account/get_active_bank_account',
    GET_BANK_ACCOUNT_LIST: 'api/bank_account/get',
    CREATE_BANK_ACCOUNT: 'api/bank_account/create',
    GET_BANK_LIST: 'api/bank/get',
    DELETE_BANK_ACCOUNT: 'api/bank_account/delete',
    UPDATE_BANK_ACCOUNT: 'api/bank_account/change_status',

    //Transfer
    TRANSFER_WALLET: 'api/transfer_wallet',
    EXCHANGE_USDT: 'api/exchange_usdt',

    //Member
    GET_USER_LIST: 'api/get_users',
    GET_MEMBER_LIST: 'api/member/get_members',
    CREATE_MEMBER: 'api/member/create',
    CREATE_BANK_MEMBER: 'api/member/create_bank_account',

    //Dashboard
    GET_TOTAL_DEPOSIT: 'api/dashboard/total_deposit',
    GET_TOTAL_SETTLEMENT_FUNDOUT: 'api/dashboard/total_settlement_fundout',
    GET_NUMBER_OF_TRANSACTION: 'api/dashboard/number_of_transaction',
    GET_DEPOSIT_SUCCESS_RATE: 'api/dashboard/deposit_success_rate',
    GET_TOTAL_DEPOSIT_CHART: 'api/dashboard/total_deposit_chart',
    GET_TOTAL_SETTLEMENT_FUNDOUT_CHART: 'api/dashboard/total_settlement_fundout_chart',
    GET_NUMBER_OF_TRANSACTION_CHART: 'api/dashboard/number_of_transaction_chart',
    GET_DEPOSIT_SUCCESS_RATE_CHART: 'api/dashboard/deposit_success_rate_chart',

    //Report
    GET_REPORT_CHANNEL: 'api/report/channel',
    GET_REPORT_FUND_TRANSFER: 'api/report/fund_transfer',
    GET_REPORT_FUND_OUT_LIST: 'api/report/fund_out',
    GET_REPORT_TOTAL_LIST: 'api/report/total',

    GET_AGENT_FAVICON: 'api/agent/get_favicon',
    GET_AGENT_LOGO: 'api/agent/get_logo',

    //Balance Log
    GET_BALANCE_LOG_LIST: 'api/balance_log/get'
}