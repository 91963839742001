import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {toast} from "react-toastify";
import {reduxActions} from "./action";
import API from '../../api/index';
import {reduxActions as reduxCommonAction} from '../common/action';
import {URLS} from "../../configs/routes_url";

function* fetchDepositList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('start_date', action.depositObject.startDate);
        formData.append('end_date', action.depositObject.endDate);
        formData.append('filter_state', action.depositObject.filterState);
        formData.append('filter_date', action.depositObject.filterDate);

        const {data} = yield call(API.post, API_URLS.GET_DEPOSIT_LIST, formData, {headers: headers})
        let depositList = [...data.result]
        yield put(reduxActions.setDepositList(depositList))

        if (depositList.length === 0) {
            toast.success("No data found", {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* createSettlement(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('bank_id', action.settlementObject.bank_id);
        formData.append('amount', action.settlementObject.amount);
        formData.append('remarks', action.settlementObject.remarks);
        formData.append('settlement_password', action.settlementObject.settlementPassword);

        const {data} = yield call(API.post, API_URLS.CREATE_SETTLEMENT, formData, {headers: headers})
        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })

        setTimeout(() => window.location.href = URLS.SETTLEMENT_HISTORY, 1000)
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* createFundOut(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();

        formData.append('amount', action.fundOutObject.amount);
        formData.append('member_id', action.fundOutObject.member_id);
        formData.append('member_bank_id', action.fundOutObject.member_bank_id);
        formData.append('remarks', action.fundOutObject.remarks);

        const {data} = yield call(API.post, API_URLS.CREATE_FUND_OUT, formData, {headers: headers})
        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })

        setTimeout(() => window.location.href = URLS.FUND_OUT_HISTORY, 1000)
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchSettlementHistoryList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}

        let formData = new FormData();
        formData.append('start_date', action.settlementObject.startDate);
        formData.append('end_date', action.settlementObject.endDate);
        formData.append('filter_state', action.settlementObject.filterState);
        formData.append('filter_date', action.settlementObject.filterDate);

        const {data} = yield call(API.post, API_URLS.GET_SETTLEMENT_HISTORY_LIST, formData, {headers: headers})
        let settlementList = [...data.result]
        yield put(reduxActions.setSettlementHistoryList(settlementList))

        if (settlementList.length === 0) {
            toast.success("No data found", {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchFundOutHistoryList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}

        let formData = new FormData();
        formData.append('start_date', action.fundOutObject.startDate);
        formData.append('end_date', action.fundOutObject.endDate);
        formData.append('filter_state', action.fundOutObject.filterState);
        formData.append('filter_date', action.fundOutObject.filterDate);

        const {data} = yield call(API.post, API_URLS.GET_FUND_OUT_HISTORY_LIST, formData, {headers: headers})
        let fundOutList = [...data.result]
        yield put(reduxActions.setFundOutHistoryList(fundOutList))

        if (fundOutList.length === 0) {
            toast.success("No data found", {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchAvailableBankList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        const searchParams = new URLSearchParams(window.location.search)
        let channel = searchParams.get('channel') || ''
        let company_number = searchParams.get('company_number') || ''
        let order_no = searchParams.get('order_no') || ''
        let amount = searchParams.get('amount') || ''
        let sign = searchParams.get('sign') || ''
        let bank_code = searchParams.get('bank_code') || ''

        let url = `${API_URLS.GET_AVAILABLE_BANK}?channel=${channel}&company_number=${company_number}&order_no=${order_no}&amount=${amount}&sign=${sign}`
        if (bank_code && bank_code.length > 0) {
            url += `&bank_code=${bank_code}`
        }
        const {data} = yield call(API.get, url)
        yield put(reduxActions.setPendingDeposit(data.result.pending_deposit))
        yield put(reduxActions.setExpiredDeposit(data.result.expired_deposit))
        yield put(reduxActions.setAvailableBankList(data.result.bankList))
        yield put(reduxActions.setAvailableBankListFailedMessage(''))

    } catch (e) {
        console.log(e);
        yield put(reduxActions.setAvailableBankListFailedMessage(e.response.data.message))
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* rejectFundOut(action: any) {
    try {

        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData()
        formData.append('id', action.id)

        const {data} = yield call(API.post, API_URLS.REJECT_FUND_OUT, formData, {headers: headers})

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        let element: HTMLElement = document.getElementById('btn-fundout-search')
        if (element) {
            element.click()
        }
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}


const transactionSaga = [
    takeLatest(SagaActionTypes.FETCH_DEPOSIT_LIST, fetchDepositList),
    takeLatest(SagaActionTypes.FETCH_SETTLEMENT_HISTORY_LIST, fetchSettlementHistoryList),
    takeLatest(SagaActionTypes.CREATE_SETTLEMENT, createSettlement),

    takeLatest(SagaActionTypes.FETCH_FUND_OUT_HISTORY_LIST, fetchFundOutHistoryList),
    takeLatest(SagaActionTypes.CREATE_FUND_OUT, createFundOut),
    takeLatest(SagaActionTypes.FETCH_AVAILABLE_BANK_LIST, fetchAvailableBankList),
    takeLatest(SagaActionTypes.REJECT_FUND_OUT, rejectFundOut),
]

export default transactionSaga
